// import package
import React, { Component } from "react"
import ReactDatatable from "@ashvin27/react-datatable";

// import component
import Navbar from "../partials/Navbar"
import Sidebar from "../partials/Sidebar"

// import lib
import { toastAlert } from '../../lib/toastAlert'
import { addExchangeCredit, exchangeCreditHistory } from "../../actions/exchangeCreditAction"
import { momentFormat } from "../../lib/dateTimeHelper"

const initialFormValue = {
    'userId': "",
    'amt': "",
    'token': ""
}
class ExchangeCredit extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
              key: "createdAt",
              text: "Date",
              className: "question",
              align: "left",
              sortable: true,
              width: 200,
              cell: (record) => {
                return <p>{momentFormat(record.createdAt)}</p>;
              },
            },
            {
              key: "userCode",
              text: "User Id",
              className: "question",
              align: "left",
              sortable: true,
              width: 200,
            },
            {
              key: "currency",
              text: "Currency",
              className: "description",
              align: "left",
              sortable: true,
            },
            {
              key: "amount",
              text: "Amount",
              className: "description",
              align: "left",
              sortable: true,
            },
          ];
      
          this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Faq",
            no_data_text: "No Faq found!",
            language: {
              length_menu: "Show _MENU_ result per page",
              filter: "Filter in records...",
              info: "Showing _START_ to _END_ of _TOTAL_ records",
              pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
              },
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
          };

        this.state = {
            records: [],
            search: "",
            page: 1,
            limit: 10,
            count: 0,
            formValue: initialFormValue,
            param: "showAll",
            loader: false
        }

        this.handlePagination = this.handlePagination.bind(this)
        this.fetchCredit = this.fetchCredit.bind(this)
    }

    componentDidMount() {
        const { page, limit, param } = this.state;
        let reqData = {
            page,
            limit,
            param,
        };
        this.fetchCredit(reqData)
        // this.fetchUser()
        // this.getBnsSetting()
    };

    async fetchCredit(reqData) {
        try {
            this.setState({ loader: true });
            const { status, loading, result, count } = await exchangeCreditHistory(reqData);
            if (status == "success") {
                this.setState({ loader: false });
                this.setState({ count: count, records: result });
            }
        } catch (err) { }
    }

    handleChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let formData = { ...this.state.formValue, [name]: value };
        this.setState({ formValue: formData });
    };

    handleSubmit = async(e) => {
        e.preventDefault();
        if(this.state.loader) return
        try {
            let reqData = this.state.formValue
            // n7ZzTHe8$!pRE!fyqzCjZsDGGW8iPn^ZMzmb5VshprLEVv9K@adu5z6
            this.setState({"loader" : true})
            let { status, message } = await addExchangeCredit(reqData)
            console.log("ok f this", status, message)
            if (status == 'success') {
                toastAlert('success', message, 'bonus')
                this.setState({ formValue: initialFormValue })
            } else {
                toastAlert('error', message, 'bonus')
            }
            this.setState({"loader" : false})
        } catch (err) { }
    }

    handlePagination(index) {
        let reqData = {
          page: index.page_number,
          limit: index.page_size,
          search: index.filter_value,
          param: this.state.param,
        };
        this.fetchCredit(reqData);
        this.setState({
          page: index.page_number,
          limit: index.page_size,
          search: index.filter_value,
        });
      }

    render() {
        const { loader, count } = this.state
        const { userId, amt, token } = this.state.formValue

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">

                        <div className="container-fluid">

                            <h3 className="mt-2 text-secondary">Exchange Credit</h3>
                            <form noValidate onSubmit={this.onSubmit} id="refer-email">
                                <div className="row mt-3">
                                    <div className="col-md-3">
                                        <label htmlFor="content">User Id : </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.handleChange}
                                            value={userId}
                                            name="userId"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="content">Amount : </label>
                                    </div>

                                    <div className="col-md-9">
                                        <input
                                            onChange={this.handleChange}
                                            value={amt}
                                            name="amt"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="content">Token : </label>
                                    </div>

                                    <div className="col-md-9">
                                        <input
                                            onChange={this.handleChange}
                                            value={token}
                                            name="token"
                                            type="text"
                                        />
                                    </div>
                                </div>

                                {
                                    !this.state.loader && 
                                    <div className="row mt-2 justify-content-end mr-4">
                                        <button
                                                onClick={this.handleSubmit}
                                                form="refer-email"
                                                className="btn btn-primary"
                                            >
                                            Submit
                                        </button>
                                    </div>
                                }
                                
                            </form>
                            <br />
                                <div className="modal-footer">
                                    <div id="page-content-wrapper">
                                        <div className="container-fluid">
                                        <h3 className="mt-2 text-secondary">Exchange Credit History</h3>

                                        <div style={{ overflow: "scroll" }}>
                                            <ReactDatatable
                                                config={this.config}
                                                records={this.state.records}
                                                columns={this.columns}
                                                dynamic={true}
                                                total_record={count}
                                                loading={loader}
                                                onChange={this.handlePagination}
                                            />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default ExchangeCredit