// import package
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import clsx from "classnames";

// import action
import { newPerpTradeBot } from "../../../actions/tradingBot";

// import lib
import { toastAlert } from "../../../lib/toastAlert";
import isEmpty from "../../../lib/isEmpty";
import validation from "./validation";

const initialFormValue = {
    coinId: "",
    coin: "",
    side: "",
    startQuantity: "",
    endQuantity: "",
};

class NewBot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currencyOptions: [],
            formValue: initialFormValue,
            loader: false,
            errors: {},
        };
    }

    componentDidMount() {
        const { currencyOptions } = this.props;
        if (currencyOptions && currencyOptions.length > 0) {
            this.setState({ currencyOptions });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { currencyOptions } = nextProps;
        if (currencyOptions && currencyOptions.length > 0) {
            this.setState({ currencyOptions });
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        const { currencyOptions } = this.state;

        let { name, value } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        if (name == "coinId") {
            let cur = currencyOptions.find((el) => el.firstCurrencyId == value);
            if (cur) {
                formData = {
                    ...formData,
                    coin: cur.firstCurrencySymbol,
                };
            }
        }
        this.setState({ formValue: formData });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { formValue } = this.state;
        this.setState({ loader: true });
        try {
            let reqData = {
                coinId: formValue.coinId,
                coin: formValue.coin,
                side: formValue.side,
                startQuantity: formValue.startQuantity,
                endQuantity: formValue.endQuantity,
            };

            const validateError = await validation(reqData);
            if (!isEmpty(validateError)) {
                this.setState({ errors: validateError });
                return;
            }

            const { status, loading, message, error } = await newPerpTradeBot(reqData);
            this.setState({ loader: loading });
            if (status == "success") {
                toastAlert("success", message, "newBot");
                this.setState({ formValue: initialFormValue });
                this.props.refetch();
            } else {
                if (!isEmpty(error)) {
                    this.setState({ errors: error });
                } else {
                    toastAlert("error", message, "newBot");
                }
            }
        } catch (err) { }
    };

    render() {
        const { coinId, side, startQuantity, endQuantity } = this.state.formValue;
        const { errors, currencyOptions, loader } = this.state;

        return (
            <form noValidate onSubmit={this.handleSubmit}>
                <h3 className="mt-2 text-secondary">Perpetual Trading bot</h3>
                <div className="row mt-2">
                    <div className="col-md-3">
                        <label htmlFor="copyright_text">Coin</label>
                    </div>
                    <div className="col-md-6">
                        <Form.Control
                            as="select"
                            custom
                            name={"coinId"}
                            value={coinId}
                            onChange={this.handleChange}
                        >
                            <option value={""}>{"Select Coin"}</option>
                            {currencyOptions &&
                                currencyOptions.length > 0 &&
                                currencyOptions.map((item, key) => {
                                    return (
                                        <option key={key} value={item.firstCurrencyId}>
                                            {item.firstCurrencySymbol}
                                        </option>
                                    );
                                })}
                        </Form.Control>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-3">
                        <label htmlFor="copyright_text">Side</label>
                    </div>
                    <div className="col-md-6">
                        <Form.Control
                            as="select"
                            custom
                            name={"side"}
                            value={side}
                            onChange={this.handleChange}
                        >
                            <option value={""}>{"Select Side"}</option>
                            <option value={"buy"}>{"Buy"}</option>
                            <option value={"sell"}>{"Sell"}</option>
                        </Form.Control>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-3">
                        <label htmlFor="startQuantity">Quantity range start</label>
                    </div>
                    <div className="col-md-6">
                        <input
                            name="startQuantity"
                            type="number"
                            min="0"
                            value={startQuantity}
                            onChange={this.handleChange}
                            error={errors.startQuantity}
                            className={clsx("form-control", {
                                invalid: errors.startQuantity,
                            })}
                        />
                        <span className="text-danger">{errors.startQuantity}</span>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-3">
                        <label htmlFor="endQuantity">Quantity range end</label>
                    </div>
                    <div className="col-md-6">
                        <input
                            name="endQuantity"
                            type="number"
                            min="0"
                            value={endQuantity}
                            onChange={this.handleChange}
                            error={errors.endQuantity}
                            className={clsx("form-control", {
                                invalid: errors.endQuantity,
                            })}
                        />
                        <span className="text-danger">{errors.endQuantity}</span>
                    </div>
                </div>

                <div className="row mt-2">
                    <button type="submit" className="btn btn-primary">
                        {loader && <i class="fas fa-spinner fa-spin"></i>}
                        Submit
                    </button>
                </div>
            </form>
        );
    }
}

NewBot.propTypes = {
    pairList: PropTypes.array.isRequired,
};

NewBot.defaultProps = {
    pairList: [],
};

export default NewBot;
