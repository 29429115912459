import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";

// import component
import CoinWithdrawModal from "../partials/CoinWithdrawModal";
import FiatWithdrawModal from "../partials/FiatWithdrawModal";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { getWithdrawList } from "../../actions/walletAction";

// impport lib
import { paymentType } from "../../lib/displayStatus";

//import downloads
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import { Button, ButtonToolbar, OverlayTrigger, Popover } from "react-bootstrap";

class Withdraw extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "createdAt",
        text: "Created date",
        className: "created_date",
        align: "left",
        sortable: true,
      },
      {
        key: "userId",
        text: "User ID",
        className: "user_id",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "toAddress",
        text: "To Address/Account",
        className: "toaddress d-flex align-items-center",
        align: "left",
        sortable: true,
        width: 200,
        cell: (record) => (
          <>
            {
              record.paymentType == "fiat_withdraw" ? 
                record.bankDetail && record.bankDetail.accountNo ?
                  record.bankDetail.accountNo 
                  : ""
                : 
              record.paymentType == "coin_withdraw" && record.toAddress
            }
            {
              record.txid == "InternalTxn" && 
              <ButtonToolbar className="ml-2">
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={
                    <Popover id="popover-trigger-hover-focus" title="Popover bottom" className="p-2">
                      <strong>This is internal transfer</strong>
                    </Popover>
                  }
                >
                  <Button variant="outline-secondary" className="rounded-circle  px-3">?</Button>
                </OverlayTrigger>
              </ButtonToolbar>
            }
          </>
        )
      },
      {
        key: "coin",
        text: "Coin",
        className: "currency",
        align: "left",
        sortable: true,
      },
      {
        key: "paymentType",
        text: "Payment Type",
        className: "currency",
        align: "left",
        sortable: true,
        cell: (record) => {
          return paymentType(record.medium);
        },
      },
      {
        key: "actualAmount",
        text: "Transfer Amount",
        className: "amount",
        align: "left",
        sortable: true,
      },
      {
        key: "status",
        text: "Status",
        className: "status",
        align: "left",
        sortable: true,
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          if (record.paymentType == "coin_withdraw") {
            return (
              <Fragment>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() =>
                    this.setState({
                      coinFormModal: true,
                      withdrawRecord: record,
                    })
                  }
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-check"></i>
                </button>
              </Fragment>
            );
          } else if (record.paymentType == "fiat_withdraw") {
            return (
              <Fragment>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() =>
                    this.setState({
                      fiatFormModal: true,
                      withdrawRecord: record,
                    })
                  }
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-check"></i>
                </button>
              </Fragment>
            );
          }
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Withdraw",
      no_data_text: "No Withdraw found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      search: "",
      page: 1,
      limit: 10,
      count: 0,
      loader: false,
      coinFormModal: false,
      fiatFormModal: false,
      withdrawRecord: {},
    };

    this.fetchWithdraw = this.fetchWithdraw.bind(this);
    this.refetchWithdraw = this.refetchWithdraw.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleCloseCoinForm = this.handleCloseCoinForm.bind(this);
    this.handleCloseFiatForm = this.handleCloseFiatForm.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.fetchWithdraw(reqData);
  }

  async fetchWithdraw(reqData) {
    this.setState({ loader: true });
    try {
      const { status, loading, result } = await getWithdrawList(reqData);
      this.setState({ loader: false });
      if (status == "success") {
        this.setState({ count: result.count, records: result.data });
      }
    } catch (err) {}
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.fetchWithdraw(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  handleCloseCoinForm() {
    this.setState({ coinFormModal: false });
  }

  handleCloseFiatForm() {
    this.setState({ fiatFormModal: false });
  }

  refetchWithdraw() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.fetchWithdraw(reqData);
  }

  async exportPDF() {
    const { records } = this.state;

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    const title = "Withdraw";
    const headers = [
      [
        "Created date",
        "User Id",
        "To Address/Account",
        "Coin",
        "PaymentType",
        "Transfer Amount",
        "Status",
      ],
    ];

    const data =
      records.length > 0 &&
      records.map((elt) => [
        // moment(elt.createdAt).format(
        //     "DD-MM-YYYY k:mm:s"
        // ),
        elt.createdAt,
        elt.userId,
        // elt.toaddress,
        elt.paymentType == "fiat_withdraw"
          ? elt.bankDetail.accountNo
          : elt.toAddress,

        elt.coin,
        paymentType(elt.paymentType),
        elt.actualAmount,
        elt.status,
      ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Withdraw.pdf");
  }

  render() {
    const {
      loader,
      count,
      records,
      coinFormModal,
      fiatFormModal,
      withdrawRecord,
    } = this.state;

    return (
      <div>
        <CoinWithdrawModal
          isShow={coinFormModal}
          onHide={this.handleCloseCoinForm}
          record={withdrawRecord}
          fetchData={this.refetchWithdraw}
        />
        <FiatWithdrawModal
          isShow={fiatFormModal}
          onHide={this.handleCloseFiatForm}
          record={withdrawRecord}
          fetchData={this.refetchWithdraw}
        />

        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Withdraw List</h3>

              {records && records.length > 0 ? (
                <button
                  onClick={this.exportPDF}
                  className="btn btn-info"
                  style={{ width: "118px", height: "35px", fontSize: "13px" }}
                >
                  Download(PDF)
                </button>
              ) : (
                ""
              )}

              {records && records.length > 0 ? (
                <CSVLink
                  data={records}
                  filename={"Withdraw.csv"}
                  className="btn btnTrade1 py-4"
                >
                  <button
                    className="btn btn-info"
                    style={{ width: "118px", height: "35px", fontSize: "13px" }}
                  >
                    Download(CSV)
                  </button>
                </CSVLink>
              ) : (
                ""
              )}

              {records && records.length > 0 ? (
                <CSVLink data={records} filename={"Withdraw.xls"}>
                  <button
                    className="btn btn-info"
                    style={{ width: "118px", height: "35px", fontSize: "13px" }}
                  >
                    Download(XLS)
                  </button>
                </CSVLink>
              ) : (
                ""
              )}
              <div style={{ overflow: "scroll" }}>
                <ReactDatatable
                  config={this.config}
                  records={records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={count}
                  loading={loader}
                  onChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Withdraw;
