import axios from 'axios'

export const addExchangeCredit = async (data) => {
    try {
        let respData = await axios({
            url: '/adminapi/creditbalance',
            method: 'post',
            data
        })
        return {
            status: 'success',
            message: respData.data.result,
        }
    } catch (err) {
        return {
            status: 'failed',
            message: err.response.data.message,
        }
    }
}

export const exchangeCreditHistory = async (data) => {
    try {
        const respData = await axios({
          url: `/adminapi/creditHistory`,
          method: "get",
          params: data,
        });
        return {
          status: "success",
          loading: false,
          result: respData.data.result,
          count: respData.data.count,
        };
      } catch (err) {
        return {
          status: "failed",
          loading: false,
        };
      }
}