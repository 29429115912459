let key = {};
if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  key = {
    secretOrKey: "FxUum76z",
    Recaptchakey: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat",
    API_URL: "https://productioncoreapi.millionero.com",
    FRONT_URL: "https://productionv2.millionero.com",
    ADMIN_URL: "https://productionv2controls.millionero.com",
    getGeoInfo: "https://ipapi.co/json/",
    BASE_NAME: '/',
    SERVICE: {
      STREAM_URL: 'https://productionstreamingapi.millionero.com'
    }

  };
} else if (process.env.REACT_APP_MODE === "staging") {
  console.log("Set pre-dev Config");
  key = {
    secretOrKey: "FxUum76z",
    Recaptchakey: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat",
    API_URL: "https://betacoreapi.millionero.com",
    FRONT_URL: "https://staging.millionero.com",
    ADMIN_URL: "https://stagingcontrols.millionero.com",
    getGeoInfo: "https://ipapi.co/json/",
    BASE_NAME: '/',
    SERVICE: {
      STREAM_URL: 'https://betastreamingapi.millionero.com'
    }
  };
} else if (process.env.REACT_APP_MODE === "development") {
  console.log("Set Development Config");
  key = {
    secretOrKey: "FxUum76z",
    Recaptchakey: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
    API_URL: "https://sandboxcoreapi.millionero.com",
    FRONT_URL: "https://dev.millionero.com",
    ADMIN_URL: "https://devcontrols.millionero.com",
    getGeoInfo: "https://ipapi.co/json/",
    BASE_NAME: '/',
    SERVICE: {
      STREAM_URL: 'https://sandboxstreamingapi.millionero.com'
    }
  };
} else {
  console.log("Set Local Config");
  key = {
    secretOrKey: "FxUum76z",
    Recaptchakey: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
    API_URL: `http://localhost:2053`,
    TRADE_URL: "http://54.211.230.83:8081/api/trade",
    SERVICE: {
      STREAM_URL: 'http://localhost:2054',
    },
    getGeoInfo: "https://ipapi.co/json/",
    BASE_NAME: '/admin',
    socialMedia: {
      facebook: {
        appId: "1034988646970193",
      },
      linkedIn: {
        clientId: "78szlpfkw7ee7s",
        redirectUrl: "https://99893158a13c.ngrok.io/signup",
        oauthUrl:
          "https://www.linkedin.com/oauth/v2/authorization?response_type=code",
        scope: "r_liteprofile%20r_emailaddress",
        state: "123456",
      },
    },
  };
}

export default key;
