// import package
import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";

// import action
import { deletePerpBot } from "../../../actions/tradingBot";

// import lib
import { toastAlert } from "../../../lib/toastAlert";

class BotList extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "coin",
                text: "Coin",
                className: "created_date",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "side",
                text: "Side",
                className: "balance",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "startQuantity",
                text: "Start Quantity Range",
                className: "deposit",
                width: 100,
                align: "left",
                sortable: false,
            },
            {
                key: "endQuantity",
                text: "End Quantity Range",
                className: "deposit",
                width: 100,
                align: "left",
                sortable: false,
            },
            {
                key: "Action",
                text: "Action",
                className: "withdraw",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                    this.setState({ withdrawModal: true, modalRecord: record });
                                    if (window.confirm("Are you sure you want to delete?")) {
                                        this.handleDelete(record._id);
                                    }
                                }}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            },
        ];

        this.config = {
            page_size: 5,
            length_menu: [10, 20, 50],
            filename: "Withdraw",
            no_data_text: "No Withdraw found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            show_length_menu: false,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            loader: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { activeBotList, loader } = nextProps;
        if (activeBotList) {
            this.setState({ records: activeBotList, loader: loader });
        }
    }

    async handleDelete(botId) {
        try {
            const { status, messaage } = await deletePerpBot(botId);
            if (status == "success") {
                this.props.refetch();
                toastAlert("success", messaage, "deleteBot");
                return;
            }
            toastAlert("error", messaage, "deleteBot");
        } catch (err) { }
    }

    render() {
        const { records, loader } = this.state;

        return (
            <div className="container-fluid">
                <h3 className="mt-2 text-secondary">Active Bot List</h3>
                <div className="form-group mb-0"></div>
                <ReactDatatable
                    config={this.config}
                    records={records}
                    columns={this.columns}
                    dynamic={true}
                    loading={loader}
                // onChange={this.handlePagination}
                />
            </div>
        );
    }
}

export default BotList;
