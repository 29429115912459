import React, { Component } from "react";
import { Form, Button, Card } from "react-bootstrap";
import Select from "react-select";

//import components
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { newBotUser } from "../../actions/tradingBot";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
  firstName: "",
  lastName: "",
  email: "",
  role: {},
};

const options = [
  {
    label: "Liquidation",
    value: "liquidation",
  },
  {
    label: "Volume",
    value: "volume",
  },
];

class LiquidationUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: initialFormValue,
      errors: {},
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let formData = { ...this.state.formValue, [name]: value };
    this.setState({ formValue: formData });
  };

  handleSelect = (selectedOption) => {
    let formData = { ...this.state.formValue, role: selectedOption.value };
    this.setState({ formValue: formData });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { formValue } = this.state;
      const { fetchData } = this.props;
      let reqData = formValue;
      let { status, loading, result, error, message } = await newBotUser(
        reqData
      );
      if (status == "success") {
        toastAlert("success", message, "newBot");
        this.setState({ formValue: initialFormValue });
      } else {
        if (!isEmpty(error)) {
          this.setState({ errors: error });
        } else {
          toastAlert("error", message, "newBot");
        }
      }
    } catch (err) {}
  };

  render() {
    const { errors } = this.state;
    const { firstName, lastName, email, role } = this.state.formValue;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Liquidation User</h3>
              <Card>
                <Card.Body>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        name={"firstName"}
                        value={firstName}
                        onChange={this.handleChange}
                      />
                      {errors.firstName && (
                        <span className="text-danger">{errors.firstName}</span>
                      )}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        name={"lastName"}
                        value={lastName}
                        onChange={this.handleChange}
                      />
                      {errors.lastName && (
                        <span className="text-danger">{errors.lastName}</span>
                      )}
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name={"email"}
                        value={email}
                        onChange={this.handleChange}
                      />
                      {errors.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Role</Form.Label>
                      <Select
                        value={
                          options.find((el) => el.value == role)
                            ? options.find((el) => el.value == role)
                            : {}
                        }
                        onChange={this.handleSelect}
                        options={options}
                      />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LiquidationUser;
